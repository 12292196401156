<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          :title="headerTitle"
          @onClickCloseButton="navigateToListPage"
      />

      <div class="row mt-2 gy-1">
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle" style="width: 92%">
              PO Details
            </label>
            <a v-if="isCreate" class="text-primary" @click="OpenPOModal"><strong>Add</strong></a>
            <table v-if="poData.length">
              <tr v-for="(item, i) in poData" :key="i">
                <td>
                  PO No: <strong>{{ item.po_number }}</strong>, PO Date: <strong>{{ item.po_date }}</strong>
                </td>
              </tr>
            </table>
            <span v-if="poData.length === 0">
              No PO added
            </span>
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Supplier</label>
            <v-select
                placeholder="Select Supplier"
                v-model="formData.contact_profile_id"
                :options="contacts"
                label="name"
                :reduce="name => name.id"
            />
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Account Head</label>
            <v-select
                placeholder="Account Head"
                v-model="formData.account_head_id"
                :options="accountPayable"
                label="name"
                :reduce="name => name.id"
            />
          </div>
          <div class="col-sm-4 mt-2">
            <label class="align-middle">Warehouse</label>
            <v-select
                placeholder="Select Warehouse"
                v-model="formData.business_location_id"
                :options="locations"
                label="text"
                :reduce="text => text.id"
            />
          </div>
          <div class="col-sm-3 mt-2">
            <label class="align-middle">GRN No</label>
            <input type="text" class="form-control" :value="formData.grn_number" readonly>
          </div>
          <div class="col-sm-3 mt-2">
            <label class="align-middle">GRN Date</label>
            <input type="date" class="form-control" v-model="formData.grn_date">
          </div>
            <div class="col-sm-2 mt-2">
              <div class="form-check mt-2">
                  <input class="form-check-input" v-model="formData.landed_costing" id="landed_costing" type="checkbox" tabindex="3" :disabled="!isCreate"/>
                  <label class="form-check-label" for="landed_costing"> Landed costing?</label>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-2 mt-1">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" data-bs-toggle="tab"  aria-controls="home" role="tab" aria-selected="true">Item Details</a>
        </li>
      </ul>
    </div>

    <div class="px-2 mt-1">
      <AddItemDetails
          class="mt-2"
          v-for="(data, index) in itemsDetails"
          :key="index"
          :index="index"
          :data="data"
          :products="products"
          type="from_po"
          @onClose="removeItem"
      />
    </div>

    <div class="p-1">
      <div class="row mt-3 px-2">
        <div class="col-12">
          <div class="mb-2">
            <label for="note" class="form-label fw-bold">Memo</label>
            <textarea v-model="formData.description" placeholder="Memo" class="form-control" rows="2" id="memo"></textarea>
          </div>
        </div>
      </div>
    </div>

    <POAddModal
        ref="poModal"
        :suppliersId="formData.contact_profile_id"
        @onSubmitPOForm="handlePOSubmit"
        @onClosePOModal="handlePOModalClose"
    />

    <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveGRN()"
              class="btn btn-primary">
        Save
      </button>
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveGRN()"
              class="btn btn-primary">Save & New
      </button>
      <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
    </div>

    <GlobalLoader/>
  </div>
</template>
<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton'
import AddItemDetails from "@/components/molecule/procurement/inventory/grn/AddItemDetails";
import AddButton from "@/components/atom/AddButton";
import handlePurchase from "@/services/modules/purchase";
import handleBusinessesLocations from "@/services/modules/businessesLocations";
import handleContact from "@/services/modules/contact";
import handleInventory from "@/services/modules/inventory";
import POAddModal from "@/views/page/company/procurement/inventory/grn/POAddModal";

const {fetchProductList, fetchAccountHead, ...rest} = handlePurchase()
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const {fetchContactProfiles} = handleContact()
const {
    fetchGrnNumber,
    createNewGRN,
    updateGRN,
    fetchSingleGRN,
  } = handleInventory()

const router = useRouter()
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess')
const companyId = computed(() => {
  return route.params.companyId
})
const poModal = ref(null)

let loader =ref(false)
let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let isCreate = ref(true)
let headerTitle = ref("GRN From PO")
let contacts = ref([])
let locations = ref([])
let products = ref([])
let accountPayable = ref([])
let poData = ref([])
let grnEditData = ref({})
let grnData = {
  general: [],
}
let formData = ref({
  company_id: companyId,
  contact_profile_id: null,
  business_location_id: null,
  account_head_id: null,
  grn_number: null,
  grn_date: null,
  landed_costing: false,
  status: 'pending',
  description: '',
  item_details: [
    {
      product_id: null,
      po_quantity : '',
      total_received : '',
      quantity: '',
      alt_unit_id: null,
      alt_unit_qty: null,
      rate: 0,
      vat: 0,
      description: ""
    }
  ],
})

const itemsDetails = computed(() => {
  return formData.value.item_details
})

function navigateToListPage() {
  delete route.query.grnId;
  router.push({name: `grn-list`, params: route.params, query: route.query});
}

function removeItem(index) {
  formData.value.item_details.splice(index, 1)
}

function saveGRN() {
  if (formData.value.contact_profile_id === null){
    showError("Please select a supplier");
    return;
  }

  let isValidationError = false;
  if (formData.value.item_details.length > 0) {
    formData.value.item_details.forEach((item) => {
      if (item.po_quantity < (item.total_received + parseInt(item.quantity))){
        isValidationError = true;
      }
    })
  }
  if (isValidationError){
    showError("Receive quantity can not be greater than available quantity.");
    return;
  }


  if (isCreate.value) {
    handleGRNSubmit();
  } else {
    handleUpdateGRN();
  }
}

function handleGRNSubmit() {
  grnData.company_id  = formData.value.company_id;
  grnData.contact_profile_id = formData.value.contact_profile_id;
  grnData.business_location_id = formData.value.business_location_id;
  grnData.account_head_id = formData.value.account_head_id;
  grnData.grn_number = formData.value.grn_number;
  grnData.grn_date = formData.value.grn_date;
  grnData.landed_costing = formData.value.landed_costing;
  grnData.grn_type = "from_po";
  grnData.status = "pending";
  grnData.description = formData.value.description;
  grnData.has_item_detail = true;

  grnData.item_details = [];
  if (formData.value.item_details.length > 0) {
    formData.value.item_details.forEach((item) => {
      let data = {};
      data.product_id = item.product_id;
      data.purchase_order_general_id = item.purchase_order_general_id;
      data.po_number = item.po_number;
      data.po_date = item.po_date;
      data.alt_unit_id = item.alt_unit_id;
      data.alt_unit_qty = item.alt_unit_qty;
      data.quantity = item.quantity;
      data.rate = item.rate;
      data.vat = item.vat;
      data.description = item.description;
      grnData.item_details.push(data);
    })
  }

  createGRN();
}

function handleUpdateGRN() {
  grnData.company_id  = formData.value.company_id;
  grnData.contact_profile_id = formData.value.contact_profile_id;
  grnData.business_location_id = formData.value.business_location_id;
  grnData.account_head_id = formData.value.account_head_id;
  grnData.grn_date = formData.value.grn_date;
  grnData.grn_number = formData.value.grn_number;
  grnData.description = formData.value.description;
  grnData.status = formData.value.status;
  grnData.has_item_detail = true;

  grnData.item_details = [];
  if (formData.value.item_details.length > 0) {
    formData.value.item_details.forEach((item) => {
      let data = {};
      data.id = item.id;
      data.grn_master_id = formData.value.id;
      data.product_id = item.product_id;
      data.purchase_order_general_id = item.purchase_order_general_id;
      data.po_number = item.po_number;
      data.po_date = item.po_date;
      data.alt_unit_id = item.alt_unit_id;
      data.alt_unit_qty = item.alt_unit_qty;
      data.quantity = item.quantity;
      data.rate = item.rate;
      data.vat = item.vat;
      data.description = item.description;
      grnData.item_details.push(data);
    })
  }

  updateGRNData();
}

function createGRN(redirect = true) {
  createNewGRN(grnData, getQuery())
      .then(res => {
        loader.value = false
        if (res.status) {
          showSuccess(res.message)
          if (redirect) navigateToListPage()
        }
        if (!res.status) {
          showError(res.message)
        }
      })
      .catch(err => {
        loader.value = false
        console.log(err)
      })
}

function updateGRNData() {
  updateGRN(grnData, route.query.grnId, getQuery())
      .then(res => {
        loader.value = false
        if (res.status) {
          showSuccess(res.message);
          navigateToListPage();
        }
        if (!res.status) {
          showError(res.message)
        }
      })
      .catch(err => {
        loader.value = false;
        console.log(err)
      })
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function setFormData() {
  if (isCreate.value) {
    formData.value.grn_date = new Date().toISOString().split('T')[0];
  } else {
    headerTitle.value = "Update GRN From PO"
    formData.value.grn_date = new Date(grnEditData.value.grn_date).toISOString().split('T')[0];
    formData.value.id = grnEditData.value.id;
    formData.value.grn_number = grnEditData.value.grn_number;
    formData.value.contact_profile_id = grnEditData.value.contact_profile_id;
    formData.value.business_location_id = grnEditData.value.business_location_id;
    formData.value.account_head_id = grnEditData.value.account_head_id;
    formData.value.description = grnEditData.value.description;
    formData.value.status = grnEditData.value.status;
    formData.value.landed_costing = !!grnEditData.value.landed_costing;

    delete formData.value.item_details;
    formData.value.item_details        = [];
    grnEditData.value.grn_general.forEach((item) => {
      item.po_quantity = item.purchase_order_general.quantity;
      item.rate = item.purchase_order_general.rate;
      item.vat = item.purchase_order_general.vat;
      let sum = 0;
      item.purchase_order_general.grn_general.forEach(obj => {
          if (obj.id < item.id)
              sum += obj.quantity;
      })
      item.total_received = sum;
      formData.value.item_details.push(item);

      poData.value.push({
        po_number: item.purchase_order_general.purchase_order_master.po_number,
        po_date: item.purchase_order_general.purchase_order_master.po_date,
      })
      const key = 'po_number';
      poData.value = [...new Map(poData.value.map(item =>
          [item[key], item])).values()];
    })
  }
}

function OpenPOModal() {
  poModal.value.toggleModal();
  poModal.value.loadSuppliers();
}

function handlePOSubmit(data) {
  formData.value.contact_profile_id = data.supplier_id;
  poData.value = data.poData;
  poModal.value.toggleModal();

  formData.value.item_details = [];
  poData.value.forEach((item) => {
    if (item.purchase_general.length > 0){
      item.purchase_general.forEach((pog) => {
        let data = {};
        if(pog.po_type === 'cs_po') {
          data.product_id = pog.cs_general.requisition_general.product_id
        }
        if(pog.po_type === 'pr_po') {
          data.product_id = pog.requisition_general.product_id
        }
        if(pog.po_type === 'new_po') {
          data.product_id = pog.product_id
        }
        data.purchase_order_general_id = pog.id;
        data.rate = pog.rate;
        data.vat = pog.vat;
        data.po_number = pog.po_number;
        data.po_date = pog.purchase_order_master.po_date;
        data.po_quantity = pog.quantity;
        data.description = "";
        if (pog.grn_general.length > 0){
          const sum = pog.grn_general.reduce((accumulator, object) => {
            return accumulator + object.quantity;
          }, 0);

          data.total_received = sum;
        }else{
          data.total_received = 0;
        }
        data.quantity = (data.po_quantity - data.total_received);

        formData.value.item_details.push(data);
      })
    }
  })
}

function handlePOModalClose() {
  console.log('handlePOModalClose')
}

function onPageReload() {
  loader.value=true
  const productRes = fetchProductList(getQuery())
  const accountHeadRes = fetchAccountHead(getQuery())
  const businessLocationRes = fetchBusinessLocationsList(getQuery())
  const payableRes = rest.fetchAccountPayable(getQuery())
  const contactRes = fetchContactProfiles(getQuery())

  if(route.query.grnId && route.query.grnId !== '' && route.query.grnId !== undefined){
    isCreate.value = false;
  }

  Promise.all([
    productRes.then(res=> {
      if(res.data) products.value = res.data
    }),
    accountHeadRes.then(res=> {
      if(res.data) accountHeads.value = res.data
    }),
    businessLocationRes.then(res=> {
      if(res.data) locations.value = res.data
    }),
    payableRes.then(res=> {
      if(res.data) {
        accountPayable.value = res.data
      }
      if(res.data?.length) {
        formData.value.account_head_id = res.data[0].id
      }
    }),
    contactRes.then(res=> {
      if(res.data) contacts.value = res.data;

      if (isCreate.value){
        const grnNumberRes = fetchGrnNumber(getQuery());
        grnNumberRes.then(res => {
          formData.value.grn_number = res;
          setFormData();
        })
      }else{
        const fetchGNR = fetchSingleGRN(route.query.grnId, getQuery());
        fetchGNR.then(res2 => {
          grnEditData.value = res2.data;
          setFormData();
        });
      }
    }),
  ])
      .then(() => {
        loader.value=false;
      })
      .catch((err)=>{loader.value=false})
}

onMounted(async () => {
  onPageReload();
})
</script>
